import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../images/close.png'
import _imports_1 from '../images/complete.png'


const _hoisted_1 = { class: "db-popbox" }
const _hoisted_2 = { class: "popbox-center" }
const _hoisted_3 = { class: "popbox-title" }
const _hoisted_4 = {
  key: 0,
  class: "popbox-text"
}



export default /*@__PURE__*/_defineComponent({
  __name: 'DBMessage',
  props: {
  title: {
    default: '',
    type: String
  },
  text: {
    default: '',
    type: String
  },
  visible: {
    default: false,
    type: Boolean
  },
  type:{
    default: false,
    type: Boolean
  }
},
  emits: ['hidePopbox'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

const close = () => {
  emits('hidePopbox')
}


return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: "popbox-close",
        onClick: close
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("img", { src: _imports_0 }, null, -1)
      ])),
      _createElementVNode("div", _hoisted_3, _toDisplayString(props.title), 1),
      (props.text)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(props.text), 1))
        : _createCommentVNode("", true),
      (__props.type)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "popbox-complete",
            onClick: close
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("img", { src: _imports_1 }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ], 512)), [
    [_vShow, props.visible]
  ])
}
}

})