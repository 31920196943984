import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "code-form" }
const _hoisted_3 = { class: "verify-login" }
const _hoisted_4 = { class: "form-ipt" }
const _hoisted_5 = { class: "form-ipt" }

import { ref, reactive, computed } from "vue";
import { useLoginStore } from "@/store/login";
import { divisionTrim } from "@/utils/common";
import Toast from "@/utils/Toast";
interface RuleType {
  code: string;
  password: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ExchangeCodePage',
  setup(__props) {

const useLogin = useLoginStore();
const params = reactive<RuleType>({
  code: "",
  password: "",
});
const userInfo = computed(() => useLogin.userInfo);
const handleClick = () => {
  if (!userInfo.value?.account) {
    if (!localStorage.getItem("userInfo")?.account) {
      Toast("请先登录账号！");
      return false;
    }
  }
  if (divisionTrim(params.code) === "") {
    Toast("请输入谷子编号");
    return false;
  }
  if (divisionTrim(params.password) === "") {
    Toast("请输入兑换码");
    return false;
  }
  useLogin
    .offlinecode(params, {})
    .then((res) => {
      if (res.data.code == "200") {
        params.code = "";
        params.password = "";
        Toast("兑换成功，请打开APP查看！");
      } else {
        Toast(res.data.msg);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("p", { class: "code-form-title" }, "线下兑换码", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("label", null, "谷子编号", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((params.code) = $event))
          }, null, 512), [
            [_vModelText, params.code]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("label", null, "兑换码", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((params.password) = $event))
          }, null, 512), [
            [_vModelText, params.password]
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: "form-btn",
        onClick: handleClick
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("span", null, "兑换", -1)
      ]))
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "m-code-text" }, [
      _createElementVNode("p", null, "谷子编号是指喷码在实体谷子背面的编号。"),
      _createElementVNode("p", null, "兑换码是指打印在谷子背卡上的兑换码。")
    ], -1))
  ]))
}
}

})