import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, unref as _unref, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../images/home-bg.png'
import _imports_1 from '../images/logo.png'
import _imports_2 from '../images/ios-dl.png'
import _imports_3 from '../images/android-dl.png'
import _imports_4 from '../images/dbgz-text1.png'
import _imports_5 from '../images/bingtu.png'
import _imports_6 from '../images/dzgz.png'
import _imports_7 from '../images/tonggui.png'
import _imports_8 from '../images/down-arrow.png'
import _imports_9 from '../images/back.png'
import _imports_10 from '../images/exchange.png'
import _imports_11 from '../images/kf-2.png'
import _imports_12 from '../images/kf-1.png'


const _hoisted_1 = { class: "swiper-wrapper-content" }
const _hoisted_2 = {
  class: "warpper-container",
  id: "warpper-container"
}
const _hoisted_3 = { class: "wrapper-slider wrapper-home" }
const _hoisted_4 = { class: "wrapper-home-content" }
const _hoisted_5 = { class: "wrapper-box" }
const _hoisted_6 = { class: "wrapper-dl-content" }
const _hoisted_7 = { class: "wrapper-slider" }
const _hoisted_8 = { class: "wrapper-conpect" }
const _hoisted_9 = { class: "conpect-leftnav" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "wrapper-slider" }
const _hoisted_12 = { class: "wrapper-conpect" }
const _hoisted_13 = { class: "conpect-leftnav" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "conpect-content" }
const _hoisted_16 = {
  class: "conpect-content-inner",
  id: "news-content"
}
const _hoisted_17 = { class: "news-list" }
const _hoisted_18 = { class: "news-list" }
const _hoisted_19 = { class: "news-list" }
const _hoisted_20 = { class: "news-list" }
const _hoisted_21 = { class: "news-context-box ql-editor" }
const _hoisted_22 = { class: "news-title" }
const _hoisted_23 = { class: "news-small-text" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = { class: "wrapper-slider secondStyle" }
const _hoisted_26 = { class: "wrapper-conpect" }
const _hoisted_27 = { class: "wrapper-change-code" }
const _hoisted_28 = { class: "change-code-ipt" }
const _hoisted_29 = { class: "change-code-ipt" }
const _hoisted_30 = { class: "wrapper-slider secondStyle" }
const _hoisted_31 = { class: "contact-text" }
const _hoisted_32 = { class: "context-p" }

import { ref, reactive, onMounted, onUnmounted,computed } from 'vue';
import PCHeader from '../components/PCHeader.vue';
import NewsComp from '../components/NewsComp.vue';
import DBMessage from '../components/DBMessage.vue';
import { useNewsStore } from "@/store/news";
import { useLoginStore } from "@/store/login";
import {  divisionTrim } from "@/utils/common";
import { useRouter,useRoute } from 'vue-router'

import isMobile from '@/utils/isMobile';
interface RulePopbox {
  title: string;
  text?: string;
  visible: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'SwiperHome',
  setup(__props) {

isMobile();

const router = useRouter()
const route = useRoute();

// 弹出框配置
const popAttr = reactive<RulePopbox>({
  title: '', // 标题
  text: '', // 内容
  visible: false, // 显示隐藏
});

const useNews = useNewsStore();
const useLogin = useLoginStore();
const index = ref<number>(0);
const isScroll = ref(true);
const move = ref(false);
const timer = ref(null);
let warpper = null;
let sliderWapper = null;
let conpectBox = null;
let newsBox = null;
const conpectIndex = ref<number>(0);
const newsIndex = ref<number>(0);
const showNews = ref<boolean>(false);
// let userId = ref(localStorage.getItem("userId"))
const userInfo = computed(() => useLogin.userInfo);
const slideParams = reactive({
  pageNum: 1,
  pageSize: 10,
  list:[]
})
const newsParams = reactive({
  pageNum: 1,
  pageSize: 3,
  total:0,
  list:[]
})
const onlineNewsParams = reactive({
  pageNum: 1,
  pageSize: 3,
  total:0,
  list:[]
})
const offlineNewsParams = reactive({
  pageNum: 1,
  pageSize: 3,
  total:0,
  list:[]
})
const zhaoPinParams = reactive({
  pageNum: 1,
  pageSize: 3,
  total:0,
  list:[]
})
const newsDetail = reactive({
  title:'',
  content:'',
  createTime:''
})
const params = reactive({
  code: "",
  password: "",
});
const newsNav = ref([
  { title: '最新', id: 0 },
  { title: '线上活动', id: 1 },
  { title: '线下活动', id: 2 },
  { title: '加入我们', id: 3 },
]);
const conpectNav = ref([
  { title: '柄图', id: 0 },
  { title: '电子谷子', id: 1 },
  { title: '痛柜', id: 2 },
]);

const toUrl = (url:string) => {
  window.open(url, '_blank');
};
const resetScreen = () => {
  var oHtml = document.querySelector('html');
  var clientW = document.documentElement.clientWidth;
  if (clientW > 750) {
    let screenWidth = (clientW / 750) * 100 < 200 ? (clientW / 750) * 100 : 200;
    oHtml.style.fontSize = screenWidth + 'px';
  }
};

const openPopbox = (payload: RulePopbox) => {
  popAttr.visible = payload.visible;
  popAttr.title = payload.title;
  popAttr.text = payload.text ? payload.text : '';
};

// 打开弹出框
openPopbox({ visible: false, title: '兑换成功！', text: '请检查兑换码是否输入错误' });

// 关闭弹出框
const hidePopbox = () => {
  popAttr.visible = false;
};

const setConpectIndex = (id: number) => {
  conpectIndex.value = id;
  conpectBox.style.top = -conpectBox.clientHeight * conpectIndex.value + 'px';
};

const setNewsIndex = (id: number) => {
  newsIndex.value = id;
  newsBox.style.top = -newsBox.clientHeight * newsIndex.value + 'px';
};

const goIndex = () => {
  (warpper as HTMLElement).style.top = -index.value * 100 + 'vh';
};

// 滚动到指定的页面
const changePage = (page: number) => {
  index.value = page;
  showNews.value = false;
  window.removeEventListener('mousewheel', wheelScroll, false);
  window.addEventListener('mousewheel', wheelScroll, false);
  goIndex();
};

const showNewsInner = (id: number) => {
  showNews.value = true;

  window.removeEventListener('mousewheel', wheelScroll, false);
  clearTimeout(timer.value);
  useNews.getNewsInfo({id}).then(res=>{
    if(res.data.code === 200){
      newsDetail.title = res.data.data.title
      newsDetail.content = res.data.data.content
      newsDetail.createTime = res.data.data.createTime
    } 
  })

};

const showNewsInner2 = (id: number) => {
  showNews.value = true;

  window.removeEventListener('mousewheel', wheelScroll, false);
  clearTimeout(timer.value);
  useNews.getZhaoPinInfo({id}).then(res=>{
    if(res.data.code === 200){
      newsDetail.title = res.data.data.title
      newsDetail.content = res.data.data.content
      newsDetail.createTime = res.data.data.createTime
    } 
  })

};

const closeNews = () => {
  showNews.value = false;
  window.addEventListener('mousewheel', wheelScroll, false);
};

const wheelScroll = (e: any) => {
  if (!isScroll.value) {
    return false;
  }

  isScroll.value = false;
  if (e.wheelDelta > 0) {
    // 判断是否在第二页
    if (index.value === 1 && conpectIndex.value > 0) {
      // 先滚动第二页数据
      setConpectIndex(--conpectIndex.value);
    } else if (index.value === 2 && newsIndex.value > 0) {
      setNewsIndex(--newsIndex.value);
    } else {
      index.value === 0 ? 0 : index.value--;
    }
  } else {
    // 判断是否在第二页
    if (index.value === 1 && conpectIndex.value < 2) {
      // 先滚动第二页数据
      setConpectIndex(++conpectIndex.value);
    } else if (index.value === 2 && newsIndex.value < 3) {
      setNewsIndex(++newsIndex.value);
    } else {
      index.value >= sliderWapper.length - 1 ? sliderWapper.length : index.value++;
    }
  }
  goIndex();
  setTimeout(() => {
    isScroll.value = true;
  }, 300);
};

const newsChange = (val:number) =>{
  newsParams.pageNum = val
  getNewsList()
}
const onlineNewsChange = (val:number) =>{
  onlineNewsParams.pageNum = val
  getOnlineNewsList()
}
const offlineNewsChange = (val:number) =>{
  offlineNewsParams.pageNum = val
  getOfflineNewsList()
}
const zhaoPinChange = (val:number) =>{
  zhaoPinParams.pageNum = val
  getZhaoPinList()
}

const subForm = ()=>{
  if (!localStorage.getItem("userName")) {
    openPopbox({ visible: true, title: '请先登录账号！' });
    return false;
  }
  if (divisionTrim(params.code) === "") {
    openPopbox({ visible: true, title: '请输入兑换码' });
    return false;
  }

  if (divisionTrim(params.password) === "") {
    openPopbox({ visible: true, title: '请输入兑换密码' });
    return false;
  }
  useLogin.offlinecode(params).then((res) => {
    if (res.data.code == "200") {
      params.code = ''
      params.password = ''
      openPopbox({ visible: true, title: '兑换成功，请打开APP查看！' });
    } else {
      openPopbox({ visible: true, title: res.data.msg });
    }
  });
}

const getNewsList = ()=>{
  let obj = {
    pageNum: newsParams.pageNum,
    pageSize: newsParams.pageSize,
  }
  useNews.getRecentNewsList(obj).then(res=>{
    if(res.data.code === 200){
      newsParams.list = res.data.rows
      newsParams.total = res.data.total
    }else{
      newsParams.list = []
      newsParams.total = 0
    }
  })
}

const getOnlineNewsList = ()=>{
  let obj = {
    pageNum: onlineNewsParams.pageNum,
    pageSize: onlineNewsParams.pageSize,
  }
  useNews.getOnlineNewsList(obj).then(res=>{
    if(res.data.code === 200){
      onlineNewsParams.list = res.data.rows
      onlineNewsParams.total = res.data.total
    }else{
      onlineNewsParams.list = []
      onlineNewsParams.total = 0
    }
  })
}

const getOfflineNewsList = ()=>{
  let obj = {
    pageNum: offlineNewsParams.pageNum,
    pageSize: offlineNewsParams.pageSize,
  }
  useNews.getOfflineNewsList(obj).then(res=>{
    if(res.data.code === 200){
      offlineNewsParams.list = res.data.rows
      offlineNewsParams.total = res.data.total
    }else{
      offlineNewsParams.list = []
      offlineNewsParams.total = 0
    }
  })
}

const getSlideList = ()=>{
  let obj = {
    pageNum: slideParams.pageNum,
    pageSize: slideParams.pageSize,
  }
  useNews.getSlideList(obj).then(res=>{
    if(res.data.code === 200){
      slideParams.list = res.data.data
    }else{
      slideParams.list = []
    }
  })
}

const getZhaoPinList = ()=>{
  let obj = {
    pageNum: zhaoPinParams.pageNum,
    pageSize: zhaoPinParams.pageSize,
  }
  useNews.getZhaoPinList(obj).then(res=>{
    if(res.data.code === 200){
      zhaoPinParams.list = res.data.rows
      zhaoPinParams.total = res.data.total
    }else{
      zhaoPinParams.list = []
      zhaoPinParams.total = 0
    }
  })

}

onMounted(() => {
  timer.value = setTimeout(() => {
    move.value = true;
  }, 300);

  // 跟进当前路由跳转到指定页面
  const currentIndex = localStorage.getItem('currentIndex');
  localStorage.removeItem('currentIndex');
  index.value = currentIndex ? parseInt(currentIndex) : 0;

  warpper = document.getElementById('warpper-container') as HTMLElement;
  conpectBox = document.getElementById('conpect-content') as HTMLElement;
  newsBox = document.getElementById('news-content') as HTMLElement;
  sliderWapper = document.querySelectorAll('.wrapper-slider');
  window.addEventListener('mousewheel', wheelScroll, false);
  goIndex();

  // 设置html字体
  resetScreen();
  window.addEventListener('resize', resetScreen, false);
  getNewsList()
  getOnlineNewsList()
  getOfflineNewsList()
  getSlideList()
  getZhaoPinList()
  if(route.query.newsId){
    let id = Number(route.query.newsId)
    changePage(2)
    showNews.value = true;
    window.removeEventListener('mousewheel', wheelScroll, false);
    // clearTimeout(timer.value);
    useNews.getNewsInfo({id}).then(res=>{
      if(res.data.code === 200&&res.data.data){
        newsDetail.title = res.data.data.title || ''
        newsDetail.content = res.data.data.content || ''
        newsDetail.createTime = res.data.data.createTime || ''
      } 
    })

  }
});

onUnmounted(() => {
  window.removeEventListener('mousewheel', wheelScroll, false);
  clearTimeout(timer.value);
  window.removeEventListener('resize', resetScreen, false);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PCHeader, {
      currentIndex: index.value,
      onChangePage: changePage,
      isHome: index.value !== 0
    }, null, 8, ["currentIndex", "isHome"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _imports_0,
          class: _normalizeClass(["wrapper-content-flower", move.value ? 'flower-move' : ''])
        }, null, 2),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[9] || (_cache[9] = _createElementVNode("img", {
                src: _imports_1,
                class: "logo-img"
              }, null, -1)),
              _createElementVNode("img", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (toUrl('https://apps.apple.com/cn/app/%E7%94%B5%E6%B3%A2%E8%B0%B7%E5%AD%90/id6499262084'))),
                src: _imports_2,
                style: {}
              }),
              _createElementVNode("img", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (toUrl('https://qimg.dianboguzi.com/app/v1.2.5.apk'))),
                src: _imports_3
              }),
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "home-qr" }, [
                _createElementVNode("div", { class: "qr-block" }),
                _createElementVNode("span", null, "扫码加入交流群")
              ], -1))
            ])
          ]),
          _createElementVNode("img", {
            src: _imports_4,
            class: _normalizeClass(["home-title-text", move.value ? 'text-move' : ''])
          }, null, 2)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conpectNav.value, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item.id,
                  class: _normalizeClass(item.id === conpectIndex.value ? 'active' : ''),
                  onClick: ($event: any) => (setConpectIndex(item.id))
                }, _toDisplayString(item.title), 11, _hoisted_10))
              }), 128))
            ])
          ]),
          _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"conpect-content\"><div class=\"conpect-content-inner\" id=\"conpect-content\"><div><img src=\"" + _imports_5 + "\" class=\"conpect-img\"></div><div><img src=\"" + _imports_6 + "\" class=\"conpect-img\"></div><div><img src=\"" + _imports_7 + "\" class=\"conpect-img\"></div></div></div>", 1))
        ]),
        _createElementVNode("a", {
          class: "next-page",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (changePage(++index.value)))
        }, _cache[12] || (_cache[12] = [
          _createElementVNode("img", { src: _imports_8 }, null, -1)
        ]))
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newsNav.value, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item.id,
                  class: _normalizeClass(item.id === newsIndex.value ? 'active' : ''),
                  onClick: ($event: any) => (setNewsIndex(item.id))
                }, _toDisplayString(item.title), 11, _hoisted_14))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(NewsComp, {
                  slideList: slideParams.list,
                  list: newsParams.list,
                  total: newsParams.total,
                  onShowNewsInner: showNewsInner,
                  onHandleCurrentChange: newsChange
                }, null, 8, ["slideList", "list", "total"])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(NewsComp, {
                  slideList: slideParams.list,
                  list: onlineNewsParams.list,
                  total: onlineNewsParams.total,
                  onShowNewsInner: showNewsInner,
                  onHandleCurrentChange: onlineNewsChange
                }, null, 8, ["slideList", "list", "total"])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(NewsComp, {
                  slideList: slideParams.list,
                  list: offlineNewsParams.list,
                  total: offlineNewsParams.total,
                  onShowNewsInner: showNewsInner,
                  onHandleCurrentChange: offlineNewsChange
                }, null, 8, ["slideList", "list", "total"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(NewsComp, {
                  slideList: slideParams.list,
                  list: zhaoPinParams.list,
                  total: zhaoPinParams.total,
                  onShowNewsInner: showNewsInner2,
                  type: "",
                  onHandleCurrentChange: zhaoPinChange
                }, null, 8, ["slideList", "list", "total"])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["news-context", showNews.value ? 'show-news' : ''])
          }, [
            _createElementVNode("a", {
              class: "news-back",
              onClick: closeNews
            }, _cache[13] || (_cache[13] = [
              _createElementVNode("img", { src: _imports_9 }, null, -1)
            ])),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("b", _hoisted_22, _toDisplayString(newsDetail.title), 1),
              _createElementVNode("span", _hoisted_23, _toDisplayString(newsDetail.createTime), 1),
              _createElementVNode("div", {
                innerHTML: newsDetail.content,
                style: {"white-space":"pre-wrap"},
                class: "news-inner-context"
              }, null, 8, _hoisted_24)
            ])
          ], 2)
        ]),
        _createElementVNode("a", {
          class: "next-page",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (changePage(++index.value)))
        }, _cache[14] || (_cache[14] = [
          _createElementVNode("img", { src: _imports_8 }, null, -1)
        ]))
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _cache[18] || (_cache[18] = _createElementVNode("b", { class: "change-code-title" }, "线下兑换码", -1)),
            _createElementVNode("div", _hoisted_28, [
              _cache[15] || (_cache[15] = _createElementVNode("label", null, "谷子编号", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((params.code) = $event)),
                type: "text"
              }, null, 512), [
                [_vModelText, params.code]
              ])
            ]),
            _createElementVNode("div", _hoisted_29, [
              _cache[16] || (_cache[16] = _createElementVNode("label", null, "兑换码", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((params.password) = $event)),
                type: "text"
              }, null, 512), [
                [_vModelText, params.password]
              ])
            ]),
            _createElementVNode("a", {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (subForm()))
            }, _cache[17] || (_cache[17] = [
              _createElementVNode("img", {
                src: _imports_10,
                alt: "兑换"
              }, null, -1)
            ]))
          ])
        ]),
        _createElementVNode("a", {
          class: "next-page",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (changePage(++index.value)))
        }, _cache[19] || (_cache[19] = [
          _createElementVNode("img", { src: _imports_8 }, null, -1)
        ]))
      ]),
      _createElementVNode("div", _hoisted_30, [
        _cache[27] || (_cache[27] = _createElementVNode("div", { class: "wrapper-conpect" }, [
          _createElementVNode("div", { class: "qr-content" }, [
            _createElementVNode("div", null, [
              _createElementVNode("img", { src: _imports_11 }),
              _createTextVNode(" QQ客服 ")
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("img", { src: _imports_12 }),
              _createTextVNode(" 微信客服 ")
            ])
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_31, [
          _cache[25] || (_cache[25] = _createElementVNode("div", { class: "contact-logo" }, [
            _createElementVNode("img", { src: _imports_1 })
          ], -1)),
          _createElementVNode("div", _hoisted_32, [
            _cache[20] || (_cache[20] = _createElementVNode("p", null, [
              _createTextVNode("ICP备案号："),
              _createElementVNode("a", {
                href: "https://beian.miit.gov.cn/",
                target: "_blank"
              }, "苏ICP备2023053104号"),
              _createTextVNode(" | 网络备案号："),
              _createElementVNode("a", {
                href: "https://beian.mps.gov.cn/#/query/webSearch",
                target: "_blank"
              }, "苏公安网备32100102010183号")
            ], -1)),
            _cache[21] || (_cache[21] = _createElementVNode("p", null, "增值电信业务经营许可证：苏B2-20240128号", -1)),
            _createElementVNode("p", {
              class: "business",
              onClick: _cache[8] || (_cache[8] = ()=>{
              _unref(router).push('/business')
            })
            }, "营业执照"),
            _cache[22] || (_cache[22] = _createElementVNode("p", null, "运营单位：电波谷子（扬州）科技有限公司", -1)),
            _cache[23] || (_cache[23] = _createElementVNode("p", null, "电波谷子（扬州）科技有限公司 版权所有", -1)),
            _cache[24] || (_cache[24] = _createElementVNode("p", null, "电子邮箱：business@dianboguzi.com", -1))
          ]),
          _cache[26] || (_cache[26] = _createElementVNode("span", null, null, -1))
        ])
      ])
    ]),
    _createVNode(DBMessage, {
      title: popAttr.title,
      text: popAttr.text,
      visible: popAttr.visible,
      onHidePopbox: hidePopbox
    }, null, 8, ["title", "text", "visible"])
  ]))
}
}

})