import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/h5/icon-jt.svg'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = {
  key: 0,
  class: "top-btn"
}
const _hoisted_3 = {
  key: 1,
  class: "banner"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "tabs-bar" }
const _hoisted_8 = { class: "news-list" }
const _hoisted_9 = { class: "list" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "title" }
const _hoisted_12 = { class: "time" }
const _hoisted_13 = { class: "list" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "title" }
const _hoisted_16 = { class: "time" }
const _hoisted_17 = { class: "list" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "title" }
const _hoisted_20 = { class: "time" }
const _hoisted_21 = { class: "list" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "title" }
const _hoisted_24 = { class: "time" }
const _hoisted_25 = {
  key: 0,
  class: "jz"
}
const _hoisted_26 = { class: "sub" }
const _hoisted_27 = {
  key: 2,
  class: "footer-btn"
}

import {
  reactive,
  ref,
  onMounted,
  nextTick,
  onBeforeMount,
} from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css";
import { useNewsStore } from "@/store/news";
import Toast from "@/utils/Toast";
interface PageType {
  pageNum: number;
  pageSize: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NewsPage',
  emits: ["showDetail", "topage"],
  setup(__props, { emit: __emit }) {

const useNews = useNewsStore();

const touchPosition = reactive({
  startTime: 0,
  startX: 0,
  startY: 0,
  endTime: 0,
  endX: 0,
  endY: 0,
});

const handleTouchStart = (e: any) => {
  touchPosition.startTime = e.timeStamp;
  touchPosition.startX = e.touches[0].clientX;
  touchPosition.startY = e.touches[0].clientY;
};
const handleTouchEnd = (e: any) => {
  touchPosition.endTime = e.timeStamp;
  touchPosition.endX = e.changedTouches[0].clientX;
  touchPosition.endY = e.changedTouches[0].clientY;

  const deltaX = touchPosition.endX - touchPosition.startX;
  const deltaY = touchPosition.endY - touchPosition.startY;

  // 判断滑动时间是否超过300ms
  if (touchPosition.endTime - touchPosition.startTime > 300) return;
  // 判断滑动方向
  if (Math.abs(deltaX) > Math.abs(deltaY)) {
    if (deltaX > 0) {
      // console.log('向右滑动');
    } else {
      // console.log('向左滑动');
    }
  } else {
    if (deltaY > 0 && !isNone.value) {
      // console.log('向下滑动');
      emits("topage", 2);
    } else if (deltaY < 0 && !isNone.value) {
      // console.log('向上滑动');
      emits("topage", 4);
    }
  }
};

const recentPage = reactive<PageType>({
  pageNum: 1,
  pageSize: 6,
});
const recentList = ref<any>([]);
const offlinePage = reactive<PageType>({
  pageNum: 1,
  pageSize: 6,
});
const offlineList = ref<any>([]);
const onlinePage = reactive<PageType>({
  pageNum: 1,
  pageSize: 6,
});
const onlineList = ref<any>([]);
const zhaopinPage = reactive<PageType>({
  pageNum: 1,
  pageSize: 6,
});
const zhaopinList = ref<any>([]);
const emits = __emit;
const pageNum = ref(6);
const isNone = ref(false);
const tabIndex = ref(0);
const mySwiper = ref(null);
const style = reactive({
  "--swiper-navigation-color": "#fff",
  "--swiper-pagination-color": "#fff",
  "--swiper-navigation-size": "20px",
});
const active = ref<number>(0);
const setSwiper = (swiper: null) => {
  mySwiper.value = swiper;
};

const onSlideChange = () => {
  tabIndex.value = mySwiper.value.activeIndex;
};

const slideTo = (index: number) => {
  tabIndex.value = index;
  mySwiper.value.slideTo(index, 300);
  active.value = index;
  // getList(index);
};
const getList = (index: number) => {
  switch (index) {
    case 0:
      useNews
        .getRecentNewsList(recentPage)
        .then((res) => {
          if (res.data.code == "200") {
            // 最新文章
            recentList.value = res.data.rows;
            // recentList.value.push(
            //   ...res.data.rows,
            //   ...res.data.rows,
            //   ...res.data.rows,
            //   ...res.data.rows,
            //   ...res.data.rows
            // );
            // if (recentList.value.length >= 1000) {
            // if (recentList.value.length >= res.data.total) {
            //   ismore.value = false;
            // } else {
            //   ismore.value = true;
            // }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      break;
    case 1:
      useNews
        .getOfflineNewsList(offlinePage)
        .then((res) => {
          if (res.data.code == "200") {
            // 线下文章
            offlineList.value = res.data.rows;
            // offlineList.value.push(...res.data.rows);
            // if (offlineList.value.length >= res.data.total) {
            //   ismore.value = false;
            // } else {
            //   ismore.value = true;
            // }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      break;

    case 2:
      useNews
        .getOnlineNewsList(onlinePage)
        .then((res) => {
          if (res.data.code == "200") {
            // 线上文章
            onlineList.value = res.data.rows;
            // onlineList.value.push(...res.data.rows);
            // if (onlineList.value.length >= res.data.total) {
            //   ismore.value = false;
            // } else {
            //   ismore.value = true;
            // }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      break;

    case 3:
      useNews
        .getZhaoPinList(zhaopinPage)
        .then((res) => {
          if (res.data.code == "200") {
            // 招聘文章
            zhaopinList.value = res.data.rows;
            // // zhaopinList.value.push(...res.data.rows);
            // if (zhaopinList.value.length >= res.data.total) {
            //   ismore.value = false;
            // } else {
            //   ismore.value = true;
            // }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      break;

    default:
      break;
  }
};

const getNowList = (pageNum: number) => {
  // switch (active.value) {
  //   case 0:
  //     recentPage.pageSize = pageNum;
  //     break;
  //   case 1:
  //     offlinePage.pageSize = pageNum;
  //     break;
  //   case 2:
  //     onlinePage.pageSize = pageNum;
  //     break;
  //   case 3:
  //     zhaopinPage.pageSize = pageNum;
  //     break;

  //   default:
  //     break;
  // }
  recentPage.pageSize = pageNum;
  offlinePage.pageSize = pageNum;
  onlinePage.pageSize = pageNum;
  zhaopinPage.pageSize = pageNum;
};
const hasMore = () => {
  isNone.value = true;
  getNowList(1000);
  getList(0);
  getList(1);
  getList(2);
  getList(3);
};
const hasNone = () => {
  isNone.value = false;
  nextTick(() => {
    // const pageNum = Math.floor(mySwiper.value?.height / 73) || 6;
    getNowList(pageNum.value);
    getList(0);
    getList(1);
    getList(2);
    getList(3);
  });
};
const showDetail = (id) => {
  emits("showDetail", id, active.value);
};
const slideList = ref([]);
const openNewsDetail = (url: string) => {
  const urlParams = new URLSearchParams(url.split("?")[1]);
  const id = urlParams.get("newsId");
  if (id) {
    emits("showDetail", id);
  } else {
    window.open(url);
  }
};
// onBeforeMount(() => {
onMounted(() => {
  useNews
    .getSlideListMobile()
    .then((res) => {
      if (res.data.code == "200") {
        slideList.value = res.data.data;
      } else {
        Toast(res.data.msg);
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      setTimeout(() => {
        pageNum.value =
          Math.floor(
            document.getElementsByClassName("news-list")[0].clientHeight / 75
          ) || 6;
        getNowList(pageNum.value);
        getList(0);
        getList(1);
        getList(2);
        getList(3);
      }, 1500);
    });
  // });
  // onMounted(() => {
  // nextTick(() => {
  // setTimeout(() => {
  //   pageNum.value =
  //     Math.floor(
  //       document.getElementsByClassName("news-list")[0].clientHeight / 73
  //     ) || 6;
  //   getNowList(pageNum.value);
  //   getList(0);
  //   getList(1);
  //   getList(2);
  //   getList(3);
  // }, 3500);
  // });

  // nextTick(() => {
  //   console.log(
  //     mySwiper.value?.height,
  //     swiperHeight.value.clientHeight,
  //     document.getElementById("swiperHeight")?.clientHeight,
  //     888899999
  //   );
  // });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isNone.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "btn",
            onClick: hasNone
          }, _cache[4] || (_cache[4] = [
            _createTextVNode(" 收起"),
            _createElementVNode("img", {
              class: "icon-down",
              src: _imports_0
            }, null, -1)
          ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_unref(Swiper), {
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            coverflowEffect: {
          rotate: 0,
          stretch: 50,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        },
            pagination: {
          dynamicBullets: true,
        },
            modules: [_unref(EffectCoverflow), _unref(Pagination)]
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slideList.value, (item) => {
                return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                  class: "slide",
                  key: item.id
                }, {
                  default: _withCtx(() => [
                    (item.jumpId)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          onClick: ($event: any) => (openNewsDetail(item.jumpId))
                        }, [
                          _createElementVNode("img", {
                            src: item.pic,
                            width: "80%"
                          }, null, 8, _hoisted_5)
                        ], 8, _hoisted_4))
                      : (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          src: item.pic,
                          width: "80%"
                        }, null, 8, _hoisted_6))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["modules"])
        ])),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", {
        class: _normalizeClass(["tabs-item", { active: tabIndex.value == 0 }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (slideTo(0)))
      }, " 最新 ", 2),
      _createElementVNode("div", {
        class: _normalizeClass(["tabs-item", { active: tabIndex.value == 1 }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (slideTo(1)))
      }, " 线下活动 ", 2),
      _createElementVNode("div", {
        class: _normalizeClass(["tabs-item", { active: tabIndex.value == 2 }]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (slideTo(2)))
      }, " 线上活动 ", 2),
      _createElementVNode("div", {
        class: _normalizeClass(["tabs-item", { active: tabIndex.value == 3 }]),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (slideTo(3)))
      }, " 加入我们 ", 2)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_unref(Swiper), {
        class: "swiper",
        style: _normalizeStyle(style),
        navigation: true,
        modules: [_unref(Navigation)],
        onSwiper: setSwiper,
        onSlideChange: onSlideChange
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(SwiperSlide), {
            class: "slide stop-swiping",
            onTouchstart: handleTouchStart,
            onTouchend: handleTouchEnd
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recentList.value, (i) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "list-item",
                    key: i.id,
                    onClick: ($event: any) => (showDetail(i.id))
                  }, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(i.title), 1),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(i.showDate), 1)
                  ], 8, _hoisted_10))
                }), 128))
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(SwiperSlide), {
            class: "slide stop-swiping",
            onTouchstart: handleTouchStart,
            onTouchend: handleTouchEnd
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(offlineList.value, (i) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "list-item",
                    key: i.id,
                    onClick: ($event: any) => (showDetail(i.id))
                  }, [
                    _createElementVNode("div", _hoisted_15, _toDisplayString(i.title), 1),
                    _createElementVNode("div", _hoisted_16, _toDisplayString(i.showDate), 1)
                  ], 8, _hoisted_14))
                }), 128))
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(SwiperSlide), {
            class: "slide stop-swiping",
            onTouchstart: handleTouchStart,
            onTouchend: handleTouchEnd
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_17, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(onlineList.value, (i) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "list-item",
                    key: i.id,
                    onClick: ($event: any) => (showDetail(i.id))
                  }, [
                    _createElementVNode("div", _hoisted_19, _toDisplayString(i.title), 1),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(i.showDate), 1)
                  ], 8, _hoisted_18))
                }), 128))
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(SwiperSlide), { class: "slide stop-swiping" }, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_21, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(zhaopinList.value, (i) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "list-item",
                    key: i.id,
                    onClick: ($event: any) => (showDetail(i.id))
                  }, [
                    _createElementVNode("div", _hoisted_23, _toDisplayString(i.title), 1),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("span", null, _toDisplayString(i.createTime), 1),
                      (i.tag)
                        ? (_openBlock(), _createElementBlock("label", _hoisted_25, _toDisplayString(i.tag), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", _hoisted_26, _toDisplayString(i.subTitle), 1)
                    ])
                  ], 8, _hoisted_22))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["style", "modules"])
    ]),
    (!isNone.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
          _createElementVNode("div", {
            class: "btn",
            onClick: hasMore
          }, _cache[5] || (_cache[5] = [
            _createTextVNode(" 更多"),
            _createElementVNode("img", { src: _imports_0 }, null, -1)
          ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})