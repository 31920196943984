import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, unref as _unref } from "vue";
const _hoisted_1 = {
  class: "news-height"
};
const _hoisted_2 = {
  key: 0,
  class: "news-ul-zp"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "news-zp-title2"
};
const _hoisted_5 = {
  class: "tag-title"
};
const _hoisted_6 = {
  key: 1,
  class: "news-ul-zp"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  class: "news-zp-title"
};
const _hoisted_9 = {
  class: "tag-title"
};
const _hoisted_10 = {
  key: 0,
  class: "tag-jz"
};
const _hoisted_11 = {
  class: "pagination-box"
};
import SwiperNews from './SwiperNews.vue';
import { ElPagination } from 'element-plus';
import 'element-plus/es/components/pagination/style/css'

import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'NewsComp',
  props: {
    type: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      default: () => []
    },
    slideList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['showNewsInner', 'handleCurrentChange'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emits = __emit;
    const total = computed(() => props.total);
    const showNewsPage = id => {
      emits('showNewsInner', id);
    };
    const handleCurrentChange = val => {
      emits('handleCurrentChange', val);
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(SwiperNews, {
        list: __props.slideList
      }, null, 8, ["list"]), !__props.type ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => showNewsPage(item.id)
        }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, [_createElementVNode("b", _hoisted_5, _toDisplayString(item.title), 1)])]), _createElementVNode("span", null, _toDisplayString(item.showDate) + " 发布", 1)], 8, _hoisted_3);
      }), 128))])) : (_openBlock(), _createElementBlock("ul", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
        return _openBlock(), _createElementBlock("li", {
          key: index,
          onClick: $event => showNewsPage(item.id)
        }, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", null, [_createElementVNode("b", _hoisted_9, _toDisplayString(item.title), 1), item.tag ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(item.tag), 1)) : _createCommentVNode("", true)]), _createElementVNode("span", null, _toDisplayString(item.subTitle), 1)]), _createElementVNode("span", null, _toDisplayString(item.createTime) + " 发布", 1)], 8, _hoisted_7);
      }), 128))])), _createElementVNode("div", _hoisted_11, [_createVNode(_unref(ElPagination), {
        onCurrentChange: handleCurrentChange,
        layout: "prev, pager, next",
        "page-size": 3,
        total: total.value
      }, null, 8, ["total"])])]);
    };
  }
});