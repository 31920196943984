import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../images/logo.png'


const _hoisted_1 = { class: "db-header" }
const _hoisted_2 = {
  key: 0,
  class: "header-unlogin"
}
const _hoisted_3 = {
  src: _imports_0,
  class: "header-logo"
}
const _hoisted_4 = {
  key: 1,
  class: "header-nick"
}
const _hoisted_5 = ["onClick"]

import { ref,reactive,onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useLoginStore } from "@/store/login";

export default /*@__PURE__*/_defineComponent({
  __name: 'PCHeader',
  props: {
  currentIndex: {
    default: 0,
    type: Number,
    required: true
  },
  isHome: {
    default: false,
    type: Boolean
  }
},
  emits: ['changePage'],
  setup(__props, { emit: __emit }) {

const name = ref(localStorage.getItem("userName"));
console.log(localStorage.getItem("userName"),'localStorage.getItem("userName")')
const props = __props
const emit = __emit
const router = useRouter()

const jump = (index: number) => {
  emit('changePage', index)
}
const logout=()=>{
  name.value = ''
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");
  localStorage.removeItem("userName");
}

const menuList = reactive([
  { title: '首页', name: 'home' },
  { title: '概览展示', name: 'home' },
  { title: '新闻', name: 'home' },
  { title: '兑换码', name: 'home' },
  { title: '联系我们', name: 'home' }
])

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!name.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
            [_vShow, props.isHome]
          ]),
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/login')))
          }, "注册/登录")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(name.value), 1),
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (logout()))
          }, "退出")
        ])),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.name,
          class: _normalizeClass(index == props.currentIndex ? 'active' : ''),
          onClick: ($event: any) => (jump(index))
        }, _toDisplayString(item.title), 11, _hoisted_5))
      }), 128))
    ])
  ]))
}
}

})