import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/h5/logo-mini.png'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "code-form stop-swiping" }
const _hoisted_3 = {
  key: 0,
  class: "verify-login"
}
const _hoisted_4 = { class: "form-ipt" }
const _hoisted_5 = { class: "form-ipt" }
const _hoisted_6 = {
  key: 1,
  class: "verify-login"
}
const _hoisted_7 = { class: "form-ipt" }
const _hoisted_8 = { class: "form-ipt" }
const _hoisted_9 = { class: "form-code" }
const _hoisted_10 = ["disabled"]

import { ref, reactive } from "vue";
import { useLoginStore } from "@/store/login";
import { checkMobile, divisionTrim, validatePassword } from "@/utils/common";
import Toast from "@/utils/Toast";
interface RuleLogin {
  mobile: string;
  password: string;
  verification: string;
  newPassWord: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ForgetPage',
  props: { showLogin: Function },
  setup(__props) {

const props = __props;
const useLogin = useLoginStore();
const countdown = ref<number>(0);
const status = ref<boolean>(false);

const params = reactive<RuleLogin>({
  mobile: "",
  password: "",
  verification: "",
  newPassWord: "",
});

let timer: any = null;

// 修改密码成功
const changePassword = () => {
  if (!validatePassword(params.password)) {
    Toast(
      "请输⼊正确的密码组合形式，由⼤写字⺟，⼩写字⺟和特殊符号，最低6位数密码组成"
    );
    return false;
  }
  if (params.newPassWord.length === 0) {
    Toast("再次输入密码不能为空");
    return false;
  }
  if (params.password !== params.newPassWord) {
    Toast("两次输入密码不一致");
    return false;
  }
  let obj = {
    mobile: params.mobile,
    password: params.password,
    verification: params.verification,
  };
  useLogin
    .findpass(obj, {})
    .then((res) => {
      if (res.data.code == "200") {
        // 打开弹出框
        Toast("修改密码成功，即将跳转到首页");
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
          props.showLogin && props.showLogin(0, false);
        }, 2000);
      } else {
        Toast(res.data.msg);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
// 倒数
const startCountdown = () => {
  countdown.value = 60; // 假设倒计时60秒
  const intervalId = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearInterval(intervalId);
    }
  }, 1000);
};
// 获取验证码
const handleSms = () => {
  if (!checkMobile(params.mobile)) {
    Toast("请输入正确的手机号");
    return false;
  }
  useLogin
    .sendPWCode(params, {})
    .then((res) => {
      if (res.data.code == "200") {
        startCountdown();
      } else {
        Toast(res.data.msg);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
// 下一步，密码设置
const nextStep = () => {
  if (!checkMobile(params.mobile)) {
    Toast("请输入正确的手机号");
    return false;
  }

  if (divisionTrim(params.verification) === "") {
    Toast("请输入验证码");
    return false;
  }
  status.value = !status.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "title" }, "忘记密码", -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[10] || (_cache[10] = _createElementVNode("p", { class: "code-form-title" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        })
      ], -1)),
      (status.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("label", null, "输入新密码", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "password",
                maxlength: 20,
                class: "textIn",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((params.password) = $event))
              }, null, 512), [
                [_vModelText, params.password]
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("label", null, "再次输入", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "password",
                maxlength: 20,
                class: "passIn",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((params.newPassWord) = $event))
              }, null, 512), [
                [_vModelText, params.newPassWord]
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[6] || (_cache[6] = _createElementVNode("label", null, "手机号", -1)),
              _withDirectives(_createElementVNode("input", {
                maxlength: 11,
                type: "text",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((params.mobile) = $event))
              }, null, 512), [
                [_vModelText, params.mobile]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[7] || (_cache[7] = _createElementVNode("label", null, "验证码", -1)),
              _createElementVNode("div", _hoisted_9, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "verify-ipt",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((params.verification) = $event)),
                  maxlength: 6
                }, null, 512), [
                  [_vModelText, params.verification]
                ]),
                _createElementVNode("button", {
                  disabled: countdown.value > 0,
                  onClick: handleSms
                }, _toDisplayString(countdown.value > 0 ? `${countdown.value}秒后重新获取` : "发送验证码"), 9, _hoisted_10)
              ])
            ])
          ])),
      (!status.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "form-btn",
            onClick: nextStep
          }, _cache[8] || (_cache[8] = [
            _createElementVNode("span", null, "下一步", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "form-btn",
            onClick: changePassword
          }, _cache[9] || (_cache[9] = [
            _createElementVNode("span", null, "确认", -1)
          ])))
    ])
  ]))
}
}

})