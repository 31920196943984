import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "swiper-warp"
}
const _hoisted_2 = ["src"]

import { ref} from 'vue'
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/scss";
import "swiper/scss/pagination";

export default /*@__PURE__*/_defineComponent({
  __name: 'SwiperNews',
  props: {
  list:{
    type: Array,
    default: () => []
  },
},
  setup(__props) {

const silderList = ref([
  require('../images/slider-1.png'),
  require('../images/slider-2.png'),
  require('../images/slider-3.png')
])
const props = __props

const toUrl = (item:any)=>{
  if(item.jumpId&&item.jumpId.length){
    window.location.href = item.jumpId;
  }
}

return (_ctx: any,_cache: any) => {
  return (__props.list.length !== 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(Swiper), {
          modules: [_unref(Autoplay), _unref(Pagination)],
          "slides-per-view": 1,
          autoplay: {
      delay: 3000,
    },
          pagination: { clickable: true }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
              return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                onClick: ($event: any) => (toUrl(item)),
                key: index,
                class: "slider-img-outer"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: item.pic,
                    class: "slider-img"
                  }, null, 8, _hoisted_2)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modules"])
      ]))
    : _createCommentVNode("", true)
}
}

})