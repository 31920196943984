import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/h5/icon-menu.png'
import _imports_1 from '@/assets/h5/icon-x.png'
import _imports_2 from '@/assets/h5/icon-bilibili.png'
import _imports_3 from '@/assets/h5/icon-wb.png'
import _imports_4 from '@/assets/h5/icon-xhs.png'
import _imports_5 from '@/assets/h5/icon-wx.png'
import _imports_6 from '@/assets/h5/icon-dy.png'
import _imports_7 from '@/assets/h5/icon-next.png'


const _hoisted_1 = { class: "menu_bar" }
const _hoisted_2 = { class: "menu_close" }
const _hoisted_3 = { class: "menu_list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "intro" }
const _hoisted_7 = { class: "desc" }
const _hoisted_8 = {
  key: 0,
  class: "logout_box"
}
const _hoisted_9 = { class: "info" }
const _hoisted_10 = {
  key: 1,
  class: "login_box"
}

import * as _ from "lodash";
import LoginPage from "./LoginPage.vue";
import ForgetPage from "./ForgetPage.vue";
import {
  ref,
  reactive,
  computed,
  onMounted,
  watch,
} from "vue";
import { useLoginStore } from "@/store/login";


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuBar',
  props: ["swiper"],
  emits: ["topage"],
  setup(__props, { emit: __emit }) {

const useLogin = useLoginStore();
const props = __props;
const emits = __emit;

const isShow = ref(false);
const islogin = ref(false);
// login 类型，0 未登录，1登录注册页面，2忘记密码页面
const showLogin = ref(0);
const userInfo = computed(() => useLogin.userInfo);
const nickname = ref();
const menuList = reactive([
  { key: 1, title: "首页", intro: "DIANBO GOODS", desc: "" },
  { key: 2, title: "概念展示", intro: "CONCEPTS", desc: "谷子、谷模与谷美" },
  { key: 3, title: "新闻", intro: "NEWS", desc: "线上与线下的最新活动" },
  {
    key: 4,
    title: "兑换码",
    intro: "TOKENS",
    desc: "来兑换你最新获得的谷子吧",
  },
  {
    key: 5,
    title: "联系我们",
    intro: "CUSTOMER SERVICE",
    desc: "让我们听到您的声音",
  },
]);

const isLastPage = computed(() => {
  return props.swiper?.activeIndex == props.swiper?.slides.length - 1;
});

const nextPage = () => {
  props.swiper.slideNext();
};

const prevPage = () => {
  props.swiper.slidePrev();
};

const slideTo = (index: any) => {
  props.swiper.slideTo(index, 300);
  isShow.value = false;
};
const goLogin = () => {
  // islogin.value = true;
  showLogin.value = 1;
};
const logout = () => {
  islogin.value = false;
  useLogin.removeAccount();
  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");
};
// 登录成功修改登录状态
const changeLogin = (type: number, login: boolean) => {
  islogin.value = login;
  showLogin.value = type;
  setTimeout(() => {
    nickname.value = localStorage.getItem("userInfo").nickname || "";
  }, 1000);
};
watch(userInfo, (nv) => {
  islogin.value = nv.nickname ? true : false;
});
const backHome = () => {
  showLogin.value = 0;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["menu_box", { active: isShow.value }])
    }, [
      (showLogin.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "menu_back",
            onClick: backHome
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("span", null, "返回首页", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "menu_btn",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isShow.value = true))
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("img", { src: _imports_0 }, null, -1)
          ]))),
      _createElementVNode("div", {
        class: "menu_bar_pop",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (isShow.value = false))
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _imports_1,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (isShow.value = false))
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["menu_item", { active: index == props.swiper?.activeIndex }]),
              key: index,
              onClick: ($event: any) => (slideTo(index))
            }, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(item.intro), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(item.desc), 1)
            ], 10, _hoisted_4))
          }), 128))
        ]),
        _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"menu_footer\" data-v-05dc4a78><a href=\"https://space.bilibili.com/3546565146839763\" target=\"_blank\" data-v-05dc4a78><img src=\"" + _imports_2 + "\" data-v-05dc4a78></a><a href=\"https://weibo.com/u/7878529698\" target=\"_blank\" data-v-05dc4a78><img src=\"" + _imports_3 + "\" data-v-05dc4a78></a><a href=\"https://www.xiaohongshu.com/user/profile/607283d9000000000100ad8b?m_source=baofeng\" target=\"_blank\" data-v-05dc4a78><img src=\"" + _imports_4 + "\" style=\"width:35px;\" data-v-05dc4a78></a><a href=\"https://work.weixin.qq.com/kfid/kfc85565933a9cf0071\" target=\"_blank\" data-v-05dc4a78><img src=\"" + _imports_5 + "\" data-v-05dc4a78></a><a href=\"https://www.douyin.com/user/MS4wLjABAAAANv-Xl5ptPJsSvu4nFfqheXAGRFMpiL3EwUULuxzgtcE\" target=\"_blank\" data-v-05dc4a78><img src=\"" + _imports_6 + "\" data-v-05dc4a78></a></div>", 1))
      ])
    ], 2),
    (!isLastPage.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "next_page_btn",
          onClick: nextPage
        }, _cache[7] || (_cache[7] = [
          _createElementVNode("img", { src: _imports_7 }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (showLogin.value == 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (islogin.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, "欢迎您，" + _toDisplayString(userInfo.value.nickname || nickname.value), 1),
                _createElementVNode("div", {
                  class: "btn",
                  onClick: logout
                }, "退出登录")
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", {
                  class: "btn",
                  onClick: goLogin
                }, "登录"),
                _cache[8] || (_cache[8] = _createElementVNode("i", null, null, -1)),
                _createElementVNode("div", {
                  class: "btn",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (showLogin.value = 1))
                }, "注册")
              ]))
        ], 64))
      : (showLogin.value == 1)
        ? (_openBlock(), _createBlock(LoginPage, {
            key: 2,
            "show-login": changeLogin
          }))
        : (_openBlock(), _createBlock(ForgetPage, {
            key: 3,
            "show-login": changeLogin
          })),
    _createElementVNode("div", {
      class: _normalizeClass(["logo", {
      mini: props.swiper?.activeIndex == 1,
      hide: props.swiper?.activeIndex == 2 || props.swiper?.activeIndex == 4,
      center: props.swiper?.activeIndex == 3,
      login: showLogin.value,
    }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["imglogo", { black: props.swiper?.activeIndex == 3 || showLogin.value }])
      }, null, 2)
    ], 2)
  ], 64))
}
}

})