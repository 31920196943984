import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/h5/logo-mini.png'


const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "code-form stop-swiping" }
const _hoisted_3 = {
  key: 0,
  class: "verify-login"
}
const _hoisted_4 = { class: "form-ipt" }
const _hoisted_5 = { class: "form-ipt" }
const _hoisted_6 = {
  key: 1,
  class: "verify-login"
}
const _hoisted_7 = { class: "form-ipt" }
const _hoisted_8 = { class: "form-ipt" }
const _hoisted_9 = { class: "form-code" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { href: "##" }
const _hoisted_12 = { class: "footer" }
const _hoisted_13 = {
  key: 0,
  class: "showProtocol"
}

import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useLoginStore } from "@/store/login";
import { checkMobile, divisionTrim } from "@/utils/common";
import Toast from "@/utils/Toast";
import Agreement from "../Agreement.vue";
import Privacy from "../Privacy.vue";
interface RuleLogin {
  mobile: string;
  passWord: string;
  verification: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  props: { showLogin: Function },
  setup(__props) {

const props = __props;
const useLogin = useLoginStore();
const router = useRouter();
const countdown = ref<number>(0);
const status = ref<boolean>(false);
const showProtocol = ref<number>(0);
const agreeProtocol = ref<boolean>(false);
const params = reactive<RuleLogin>({
  mobile: "",
  passWord: "",
  verification: "",
});
// 协议跳转
const toHref = (type: number) => {
  showProtocol.value = type;
};
const goBack = () => {
  showProtocol.value = 0;
};
// 登录/注册
const handleLoginM = () => {
  if (!agreeProtocol.value) {
    Toast("请勾选协议");
  } else {
    if (status.value) {
      handleYzmLogin();
    } else {
      handleAccountLogin();
    }
  }
};
// 账号密码登录
const handleAccountLogin = () => {
  if (!checkMobile(params.mobile)) {
    Toast("请输入正确的手机号");
    return false;
  }

  if (divisionTrim(params.passWord) === "") {
    Toast("请输入密码");
    return false;
  }

  useLogin
    .login(params, {})
    .then((res) => {
      if (res.data.code == "200") {
        useLogin.token = res.data.data.token;
        localStorage.setItem("token", useLogin.token);
        useLogin.getUserInfo().then((res) => {
          if (res.data.code == "200") {
            useLogin.userInfo = { ...res.data.data };
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          }
        });
        props.showLogin && props.showLogin(0, true);
      } else {
        Toast(res.data.msg);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
// 倒数
const startCountdown = () => {
  countdown.value = 60; // 假设倒计时60秒
  const intervalId = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearInterval(intervalId);
    }
  }, 1000);
};
// 获取验证码
const handleSms = () => {
  if (!checkMobile(params.mobile)) {
    Toast("请输入正确的手机号");
    return false;
  }
  useLogin
    .sendSMSCode(params, {})
    .then((res) => {
      if (res.data.code == "200") {
        startCountdown();
      } else {
        Toast(res.data.msg);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
// 验证码登录
const handleYzmLogin = () => {
  if (!checkMobile(params.mobile)) {
    Toast("请输入正确的手机号");
    return false;
  }

  if (divisionTrim(params.verification) === "") {
    Toast("请输入验证码");
    return false;
  }

  useLogin
    .smsLogin(params, {})
    .then((res) => {
      if (res.data.code == "200") {
        useLogin.token = res.data.data.token;
        localStorage.setItem("token", useLogin.token);
        props.showLogin && props.showLogin(0, true);
      } else {
        Toast(res.data.msg);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
const forgetPassword = () => {
  props.showLogin && props.showLogin(2, false);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[14] || (_cache[14] = _createElementVNode("p", { class: "code-form-title" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          })
        ], -1)),
        (!status.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _cache[8] || (_cache[8] = _createElementVNode("label", null, "手机号", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "textIn",
                  maxlength: 11,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((params.mobile) = $event))
                }, null, 512), [
                  [_vModelText, params.mobile]
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[9] || (_cache[9] = _createElementVNode("label", null, "密码", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "password",
                  maxlength: 20,
                  class: "passIn",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((params.passWord) = $event))
                }, null, 512), [
                  [_vModelText, params.passWord]
                ]),
                _createElementVNode("i", {
                  class: "forget",
                  onClick: forgetPassword
                }, "忘记密码？")
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[10] || (_cache[10] = _createElementVNode("label", null, "手机号", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  maxlength: 11,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((params.mobile) = $event))
                }, null, 512), [
                  [_vModelText, params.mobile]
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[11] || (_cache[11] = _createElementVNode("label", null, "验证码", -1)),
                _createElementVNode("div", _hoisted_9, [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "verify-ipt",
                    maxlength: 6,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((params.verification) = $event))
                  }, null, 512), [
                    [_vModelText, params.verification]
                  ]),
                  _createElementVNode("button", {
                    disabled: countdown.value > 0,
                    onClick: handleSms
                  }, _toDisplayString(countdown.value > 0 ? `${countdown.value}秒后重新获取` : "发送验证码"), 9, _hoisted_10)
                ])
              ])
            ])),
        _createElementVNode("div", {
          class: "form-btn",
          onClick: handleLoginM
        }, _cache[12] || (_cache[12] = [
          _createElementVNode("span", null, "登录/注册", -1)
        ])),
        _createElementVNode("div", {
          class: "links",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (status.value = !status.value))
        }, [
          _createElementVNode("a", _hoisted_11, _toDisplayString(status.value ? "账号密码登录" : "验证码登录"), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((agreeProtocol).value = $event))
          }, null, 512), [
            [_vModelCheckbox, agreeProtocol.value]
          ]),
          _createElementVNode("div", null, [
            _cache[13] || (_cache[13] = _createTextVNode(" 同意 ")),
            _createElementVNode("a", {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (toHref(1)))
            }, "《用户协议》"),
            _createElementVNode("a", {
              onClick: _cache[7] || (_cache[7] = ($event: any) => (toHref(2)))
            }, "《隐私协议》")
          ])
        ])
      ])
    ]),
    (showProtocol.value != 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("span", {
            class: "back",
            onClick: goBack
          }, "返回"),
          (showProtocol.value == 1)
            ? (_openBlock(), _createBlock(Agreement, { key: 0 }))
            : (showProtocol.value == 2)
              ? (_openBlock(), _createBlock(Privacy, { key: 1 }))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})